import {Injectable, EventEmitter} from '@angular/core';
import {AngularFirestore, DocumentChangeAction, DocumentSnapshot} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {finalize, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { UserModel } from '../models/user.model';
import {AngularFireAuth} from '@angular/fire/auth';

import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor(
    private afAuth: AngularFireAuth,
    public db: AngularFirestore,
    private dbStorage: AngularFireStorage,
  ) {
  }

  static getDataPayload(payload: any) {
    let data: any;
    if (Object.entries(payload).length === 0 && payload.constructor === Object) {
      data = payload;
    } else {
      data = payload.data();
      data.id = payload.id;
    }
    return data;
  }

  /* User */
  getUser(userKey) {
    return this.db.collection('user').doc(userKey).snapshotChanges();
  }


  getUserType(value = 'student') {
    return this.db.collection('user', ref => {
      return ref.where('type', '==', value);
    })
      .snapshotChanges();
  }


  getuserbyID(idUser) {
    return this.db.collection('user', ref => {
      return ref.where('id', '==', idUser);
    }).snapshotChanges();
  }




  getuserForm(id) {
    return this.db.collection('data_form_id', ref => {
      return ref.where('id', '==', id);
    }).snapshotChanges();
  }






  getUserToken(userId, token) {
    return this.db.collection('user', ref => {
      return ref.where('id', '==', userId).where('token', '==', token);
    })
      .snapshotChanges();
  }

  validateUserEmail(email) {
    return this.db.collection('user').ref.where('email', '==', email).get();
  }

  createUser(value) {
    return this.db.collection('user').add({
      nombre: value.nombre,
      apellidos: value.apellidos,
      email: value.email,
      role: value.role,
      token: 'yesToken',
      date: new Date().toISOString().substring(0, 10)
    })
    .then(ref => {
      ref.set({id: ref.id}, {merge: true});
    });
  }

  updateUser(userKey, value) {
    return this.db.collection('user').doc(userKey).update(value);
  }

  deleteUser(userKey) {
    return this.db.collection('user').doc(userKey).delete();
  }

  searchUsers(searchValue) {
    return this.db.collection('user', ref => ref.where('nameToSearch', '>=', searchValue)
      .where('nameToSearch', '<=', searchValue + '\uf8ff'))
      .snapshotChanges();
  }

  searchUsersByAge(value) {
    return this.db.collection('user', ref => ref.orderBy('age').startAt(value)).snapshotChanges();
  }

  /* Document */
  getDocument(documentKey) {
    return this.db.collection('document').doc(documentKey).snapshotChanges();
  }

  getDocuments() {
    return this.db.collection('document').snapshotChanges();
  }

  getMyDocuments(idUser: string) {
    return this.db.collection('document', ref => {
      return ref.where('id_user', '==', idUser);
    }).snapshotChanges();
  }

  getDocumentStudent(items: string) {
    return this.db.collection('document', ref => {
      return ref.where('id_student', 'array-contains', items);
    }).snapshotChanges();
  }

  uploadDocuments(value: any, uploadProgress) {
    return new Promise((resolve, reject) => {
      let count = 0;
      const uploadURL: any = [];
      const index = 0;
      for (const file of value.files) {
        const filepath = `images/${file.name}`;
        const fileRef = this.dbStorage.ref(filepath);
        // Upload image
        const task = this.dbStorage.upload(filepath, file);
        // Observe percentage changes
        uploadProgress = task.snapshotChanges()
          .pipe(map(s => (s.bytesTransferred / s.totalBytes) * 100));
        /*percentageChanges.subscribe(percentage => {
          percentageArray[index] = percentage; // <--- just put new percentage to needed index
          index++;
          console.log(percentageArray);
        });*/
        // Get notified when the download URL is available
        task.snapshotChanges()
          .pipe(finalize(() => {
            fileRef.getDownloadURL().subscribe((res) => {
              uploadURL[count] = res;
              ++count;
              if (count === value.files.length) {
                resolve(uploadURL);
              }
            });
          }))
          .subscribe();
      }
    });
  }

  createDocument(value) {
    return this.db.collection('document').add({
      title: value.title,
      files: value.files,
      id_user: JSON.parse(localStorage.getItem('user')).id,
      date: new Date().toISOString().substring(0, 10)
    })
      .then(ref => {
        ref.set({document_id: ref.id}, {merge: true});
      });
  }

  updateDocument(documentKey, value) {
    return this.db.collection('document').doc(documentKey).update(value);
  }

  deleteDocument(documentKey) {
    return this.db.collection('document').doc(documentKey).delete();
  }

  /* DataForm */
  getDataForm(dataFormKey) {
    return this.db.collection('dataForm').doc(dataFormKey).snapshotChanges();
  }

  getDataForms() {
    return this.db.collection('dataForm').snapshotChanges();
  }

  getDataFormUser(idUser) {
    return this.db.collection('dataForm', ref => {
      return ref.where('id_user', '==', idUser);
    }).snapshotChanges();
  }

  getDataFormUser2() {
    return this.db.collection('dataForm').snapshotChanges();
  }


  getUserTypeTeacher(value = 'teacher'): Observable<DocumentChangeAction<UserModel>[]> {
    return this.db.collection<UserModel>('user', ref => {
      return ref.where('type', '==', value);
    })
      .snapshotChanges();
  }



  getDataFormTypeCourse(typeCourse) {
    return this.db.collection('dataForm', ref => {
      return ref.where('typecourse', '==', typeCourse);
    }).snapshotChanges();
  }


  getDataFormID(data_form_id) {
    return this.db.collection('dataForm', ref => {
      return ref.where('data_form_id', '==', data_form_id);
    }).snapshotChanges();
  }







  getDataFormId(id) {
    return this.db.collection('dataForm', ref => {
      return ref.where('data_form_id', '==', id);
    }).snapshotChanges();
  }


  getdataForm(dataFormKey) {
    return this.db.collection('dataForm').doc(dataFormKey).snapshotChanges();
  }


  

  getDataFormStudent(idStudent) {
    return this.db.collection('dataForm', ref => {
      return ref.where('id_student', '==', idStudent);
    }).snapshotChanges();
  }

  createDataForm(value) {
    return this.db.collection('dataForm').add({
      title: value.title,
      values: value.values,
      id_student: '',
      list_captures: value.list_captures,
      id_user: JSON.parse(localStorage.getItem('user')).id,
      date: new Date().toISOString().substring(0, 10)
    })
      .then(ref => {
        ref.set({data_form_id: ref.id}, {merge: true});
      });
  }

  updateDataForm(dataFormKey, value) {
    console.log(value);
    return this.db.collection('dataForm').doc(dataFormKey).update(value);
  }

  deleteDataForm(dataFormKey) {
    return this.db.collection('dataForm').doc(dataFormKey).delete();
  }

  login(email) {
    return this.db.collection('user', ref => {
      return ref
        .where('email', '==', email)
        .where('token', '==', 'yesToken');
    })
      .snapshotChanges();
  }


  public getApplicants() {
    return this.db.collection('studentForm').snapshotChanges();

  }

  getTareasCalendar() {
    return this.db.collection('calendarAdmin').snapshotChanges();
  }


  createAlumno(value) {
    return this.db.collection('clientes').add({
      creadoId: value.creadoId,
      creadoPor: value.creadoPor,
      nombre: value.nombre,
     telefono: value.telefono,
      tipo: value.tipo,
      habitaciones: value.habitaciones,
      estado: value.estado,
      zona: value.zona,
      precio: value.precio,
      observaciones: value.observaciones,
      date: new Date().toISOString(),
    })
    .then(ref => {
      ref.set({alumno_id: ref.id}, {merge: true});
    });
  }

  actualizarEmpleado(id: string, data:any): Promise<any> {
    return this.db.collection('clientes').doc(id).update(data);
  }







  agregarAlumno(alumno: any): Promise<any> {
    return this.db.collection('clientes').add(alumno).then(ref => {
      ref.set({alumno_id: ref.id}, {merge: true});
    });
    
  }


  getEventById(alumno_id: string) {
    return this.db.collection('clientes').doc(alumno_id).get();
  }

  getUsers() {
    return this.db.collection('clientes').snapshotChanges();
  }

  getUsers2() {
    return this.db.collection('user').snapshotChanges();
  }

  getUserById(id: string) {
    return this.db.collection('clientes').doc(id).get();
  }


  // getuserby(id) {
  //   return this.db.collection('clientes', ref => {
  //     return ref.where('alumno_id', '==', id);
  //   }).snapshotChanges();
  // }

  getuserby(id: string): Observable<any> {
    return this.db.collection('clientes').doc(id).get().pipe(
      map((snapshot: DocumentSnapshot<any>) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          return { id: snapshot.id, ...data } as any;
        }
        return null; // Manejar el caso en el que el documento no existe
      })
    );
  }

  
  getAlumno(userKey) {
    return this.db.collection('clientes').doc(userKey).snapshotChanges();
  }




  updateclientes(key, value) {
    return this.db.collection('clientes').doc(key).update(value);
  }


  eliminarAlumno(id: string): Promise<any> {
    return this.db.collection('clientes').doc(id).delete();
  }



  authSingUp(email, password) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(email, password)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  deleteFile(documentKey) {
    return this.db.collection('clientes').doc(documentKey).delete();
  }





  


  

  


 
}
