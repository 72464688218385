<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a routerLink="/" class="logo logo-dark">
                    <span class="logo-sm">
            <img src="assets/images/alexcasa_.png" alt="" height="32">
          </span>
                    <span class="logo-lg">
            <img src="assets/images/alexcasa.png" alt="" height="50">
          </span>
                </a>

                <a routerLink="/" class="logo logo-light">
                    <span class="logo-sm">
            <img src="assets/images/alexcasa.png" alt="" height="32">
          </span>
                    <span class="logo-lg">
            <img src="assets/images/alexcasa_.png" alt="" height="32">
          </span>
                </a>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

            <!-- App Search-->


        </div>

        <div class="d-flex">

            <!-- <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
                <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu aria-labelledby="page-header-search-dropdown">

                    <form class="p-3">
                        <div class="m-0">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div> -->






            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{name}}</span>

          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <!-- <a class="dropdown-item" routerLink="/contacts/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
                    <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-wallet font-size-16 align-middle me-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a>
                    <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge bg-success float-end">11</span><i class="bx bx-wrench font-size-16 align-middle me-1"></i>{{
            'HEADER.LOGIN.SETTINGS' | translate}} </a>
                    <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a>
                    <div class="dropdown-divider"></div> -->
                    <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Logout</a>
                </div>
            </div>



            <!-- <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown" ngbDropdownToggle>
        <i class="bx bx-bell bx-tada"></i>
        <span class="badge bg-danger rounded-pill">3</span>
      </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
                            </div>
                            <div class="col-auto">
                                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
                            </div>
                        </div>
                    </div>
                    <ngx-simplebar style="position: relative; height: 230px;">
                        <a href="javascript: void(0);" class="text-reset notification-item">
                            <div class="media">
                                <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                  <i class="bx bx-cart"></i>
                </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="javascript: void(0);" class="text-reset notification-item">
                            <div class="media">
                                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' | translate}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="javascript: void(0);" class="text-reset notification-item">
                            <div class="media">
                                <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-success rounded-circle font-size-16">
                  <i class="bx bx-badge-check"></i>
                </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' | translate}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="javascript: void(0);" class="text-reset notification-item">
                            <div class="media">
                                <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' | translate}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ngx-simplebar>
                    <div class="p-2 border-top d-grid">
                        <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                            <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{{
              'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}</span>
                        </a>
                    </div>
                </div>
            </div> -->

            <!-- <div class="dropdown d-inline-block">
                <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
            </div> -->

        </div>
    </div>
</header>