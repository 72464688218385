// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: "AIzaSyAMwvkUriUDDx_Ljt7ctF6vi4rt1qPadto",
  authDomain: "alexcasa-12589.firebaseapp.com",
  projectId: "alexcasa-12589",
  storageBucket: "alexcasa-12589.appspot.com",
  messagingSenderId: "47630200120",
  appId: "1:47630200120:web:77c0bb6d37c5c1ed40a5cd",
  measurementId: "G-ZZZ5K4QXTX"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
