import { Component , OnInit} from '@angular/core';
import { HostListener } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }

  @HostListener('window:resize')
  onWindowResize() {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (window.innerHeight > window.innerWidth) {
      viewport.setAttribute('content', 'height=' + window.innerHeight + ', width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
    } else {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
    }
  }
}
