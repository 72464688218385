<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>



    <div id="sidebar-menu">
        <ul class="metismenu list-unstyled" id="side-menu">

            <!-- <li class="menu-title">Hello</li> -->
            <li>
                <a class="side-nav-link-ref" [routerLink]="['/dashboard']" routerLinkActive="active">
                    <i class="bx bx-home-alt"></i>
                    <span>Home</span>
                </a>
            </li>

            <li>
                <a class="side-nav-link-ref" [routerLink]="['/users']" routerLinkActive="active">
                    <i class="bx bx-user"></i>
                    <span> Comprador </span>
                </a>
            </li>

            <li>
                <a class="side-nav-link-ref" [routerLink]="['/crearComprador']" routerLinkActive="active">
                    <i class="bx bx-user-plus"></i>
                    <span> Añadir Comprador </span>
                </a>
            </li>






        </ul>
    </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
    <ngx-simplebar class="h-100" #componentRef>
        <div *ngIf="!isCondensed">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
        <div *ngIf="isCondensed">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
    </ngx-simplebar>
</div>
<!-- Left Sidebar End -->