import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(
    public router: Router,
    private route: ActivatedRoute,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole: any = route.data.expectedRole;
    const item = localStorage.getItem('user');
    if (expectedRole !== undefined && expectedRole.indexOf('register') > -1) {
      const userId = route.queryParams.userId !== undefined ? route.queryParams.userId : '';
      const token = route.queryParams.token !== undefined ? route.queryParams.token : '';
      const email = route.queryParams.email !== undefined ? route.queryParams.email : '';
      if (userId === '' || token === '' || email === '') {
        this.router.navigate(['no-found']);
        return false;
      }
      return true;
    }
    if (item === '' || item === null) {
      this.router.navigate(['/account/login']);
      return false;
    }
    if (expectedRole !== undefined && expectedRole.indexOf(JSON.parse(item).type) < 0) {
      this.router.navigate(['home']);
      return false;
    }
    return true;
  }
}
